@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

@layer base {
	:root {
		--primary-color: #050505;
		--light-primary-color: #050505b2;
		--secondary-color: #f97316;
		--gray: #6d7280;
		--background: 0 0% 100%;
		--foreground: 222.2 84% 4.9%;
		--card: 0 0% 100%;
		--card-foreground: 222.2 84% 4.9%;
		--popover: 0 0% 100%;
		--popover-foreground: 222.2 84% 4.9%;
		--primary: 222.2 47.4% 11.2%;
		--primary-foreground: 210 40% 98%;
		--secondary: 210 40% 96.1%;
		--secondary-foreground: 222.2 47.4% 11.2%;
		--muted: 210 40% 96.1%;
		--muted-foreground: 215.4 16.3% 46.9%;
		--accent: 210 40% 96.1%;
		--accent-foreground: 222.2 47.4% 11.2%;
		--destructive: 0 84.2% 60.2%;
		--destructive-foreground: 210 40% 98%;
		--border: 214.3 31.8% 91.4%;
		--input: 214.3 31.8% 91.4%;
		--ring: 222.2 84% 4.9%;
		--radius: 0.5rem;
		--chart-1: 12 76% 61%;
		--chart-2: 173 58% 39%;
		--chart-3: 197 37% 24%;
		--chart-4: 43 74% 66%;
		--chart-5: 27 87% 67%;
	}

	.dark {
		--background: 222.2 84% 4.9%;
		--foreground: 210 40% 98%;
		--card: 222.2 84% 4.9%;
		--card-foreground: 210 40% 98%;
		--popover: 222.2 84% 4.9%;
		--popover-foreground: 210 40% 98%;
		--primary: 210 40% 98%;
		--primary-foreground: 222.2 47.4% 11.2%;
		--secondary: 217.2 32.6% 17.5%;
		--secondary-foreground: 210 40% 98%;
		--muted: 217.2 32.6% 17.5%;
		--muted-foreground: 215 20.2% 65.1%;
		--accent: 217.2 32.6% 17.5%;
		--accent-foreground: 210 40% 98%;
		--destructive: 0 62.8% 30.6%;
		--destructive-foreground: 210 40% 98%;
		--border: 217.2 32.6% 17.5%;
		--input: 217.2 32.6% 17.5%;
		--ring: 212.7 26.8% 83.9%;
		--chart-1: 220 70% 50%;
		--chart-2: 160 60% 45%;
		--chart-3: 30 80% 55%;
		--chart-4: 280 65% 60%;
		--chart-5: 340 75% 55%;
	}
}

@layer base {
	* {
		@apply border-border;
	}
	body {
		@apply bg-background text-foreground;
	}
}
@layer components {
	.btn-loader:disabled::before {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;
		width: 25px;
		height: 25px;
		border-radius: 50%;
		border: 2px solid;
		border-top-color: white;
		-webkit-animation: loader 0.8s linear infinite;
		animation: loader 0.8s linear infinite;
	}

	.btn-loader:disabled {
		position: relative;
		color: var(--dark-color) !important;
		background: black;
	}

	.btn-loader-outline:disabled::before {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;
		width: 25px;
		height: 25px;
		border-radius: 50%;
		border: 2px solid;
		border-top-color: var(--primary-color);
		-webkit-animation: loader 0.8s linear infinite;
		animation: loader 0.8s linear infinite;
	}

	.btn-loader-outline:disabled {
		position: relative;
		color: #ffffff !important;
		/* background: black; */
	}
}

@keyframes loader {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

:root {
	--primary-color: #050505;
	--light-primary-color: #050505b2;
	--secondary-color: #f97316;
	--gray: #6d7280;
	--react-international-phone-height: 4.5rem;
}

/* Box sizing rules */
*,
*::before,
*::after {
	box-sizing: border-box;
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
	isolation: isolate;
}

*::-webkit-scrollbar {
	display: none;
}

.no-hide::-webkit-scrollbar {
	display: block;
}

.table *::-webkit-scrollbar {
	display: none;
}

html {
	font-size: 62.5%;
}

@media (max-width: 768px) {
	html {
		font-size: 56.25%;
	}
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
	margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
	list-style: none;
}

/* Set core root defaults */
html:focus-within {
	scroll-behavior: smooth;
}

/* Set core body defaults */
body {
	min-height: 100vh;
	text-rendering: optimizeSpeed;
	line-height: 1.5;
	font-size: 1.6rem;
	font-family: inter;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
	text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
	max-width: 100%;
	display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
	font: inherit;
}

input:focus,
textarea:focus,
select:focus {
	outline: none;
	border-color: var(--secondary-color);
}

input:disabled,
textarea:disabled,
select:disabled,
button:disabled {
	cursor: not-allowed;
}

input::placeholder {
	font-size: 1.4rem;
	color: var(--gray);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px white inset !important;
}

.authBg {
	background-image: url("src/assets/images/bg.png");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

.ps-menuitem-root {
	margin-bottom: 2px;
	border-radius: 5px;
	overflow: hidden;
}

.ps-menu-icon svg {
	width: 23px !important;
	height: 23px !important;
	color: #4b5563;
}

.ps-menu-root {
	padding-inline: 2rem;
	padding-block: 2.5rem;
}

.ps-menu-button {
	padding: 5px 0 !important;
	font-weight: 500;
	font-size: 1.6rem;
	height: auto !important;
}

.ps-menu-label {
	font-weight: 500;
	font-size: 1.6rem;
	color: #4b5563;
}

.ps-menu-label.ps-active,
.ps-menu-button.ps-open > span {
	color: #050505;
}

.ps-menu-icon.ps-active svg,
.ps-menu-button.ps-open > span svg {
	color: #050505;
}

.ps-menu-button:hover {
	background-color: #e5e7eb !important;
}

.ps-menu-button.ps-active {
	background-color: #e5e7eb !important;
}

.ps-submenu-content {
	background-color: #f9fafb !important;
	display: grid;
}

.ps-menu-button[data-iscustomcollapsed="false"] + div.ps-submenu-content > ul {
	width: 90%;
	margin-left: auto;
	padding-left: 5%;
	border-left: 1px solid #e5e7eb;
}

.ps-menu-button[data-iscustomcollapsed="true"] + div.ps-submenu-content > ul {
	--block-padding: 10px;
	height: calc(98px + (var(--block-padding) * 2)) !important;
	padding-block: var(--block-padding);
	padding-inline: 5px;
	border-radius: 10px;
}

.ps-menu-button[data-iscustomcollapsed="true"] > span.ps-submenu-expand-icon {
	display: none !important;
}

.ps-submenu-expand-icon {
	width: 15px;
}

.ps-submenu-expand-icon > span {
	width: 8px;
	height: 8px;
}

.custom-pagination {
	display: flex;
	gap: 10px;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.custom-pagination-page {
	width: 30px;
	aspect-ratio: 1;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--gray);
}

.custom-pagination-page.selected {
	background-color: var(--primary-color);
	color: white;
}

.custom-pagination-previous,
.custom-pagination-next {
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1.4rem;
	line-height: 2rem;
	font-weight: 500;
	border: 1px solid var(--lightGray);
	color: var(--body-text-2);
}

.react-tabs__tab-list {
	display: flex;
	padding: 0.5rem;
	border-radius: 0.4rem;
	background-color: #f3f4f6;
	color: #4b5563;
	font-size: 1.4rem;
	width: fit-content;
	margin-bottom: 2rem;
}

.react-tabs__tab--selected {
	color: #111827;
	font-weight: 600;
	background-color: white;
}

.react-tabs__tab {
	padding-block: 1rem;
	padding-inline: 1.5rem;
	border-radius: 0.4rem;
	cursor: pointer;
}

.react-tabs__tab:focus {
	outline: none;
	border: none;
}

.modal {
	margin-top: 50px;
	height: 800px;
}

.app-modal-content {
	border-radius: 10px;
	background: #fff;
	border: none;
	margin: 0 auto;
}

.modal-header::before,
.modal-header::after {
	display: none;
}

.modal-header h4.modal-title {
	font-size: 1.6rem;
	color: var(--primary-color);
}

.modal-header .close {
	padding: 10px;
	border: none;
	font-size: 2.4rem;
}

.modal-xlg {
	width: 1200px !important;
	max-width: 1200px;
}

.modal-lg {
	width: 900px !important;
	max-width: 900px;
}

.modal-md {
	width: 600px !important;
	max-width: 600px;
}

.modal-sm {
	width: 500px !important;
	max-width: 500px;
}

.modal-normal {
	width: 750px !important;
	max-width: 900px;
}

.modal-xsm {
	width: 400px !important;
	max-width: 400px;
}

.modal-xsm .modal-title {
	font-size: 0.5rem;
}

.modal-subtitle {
	margin-bottom: 30px;
	margin-top: -20px;
	font-size: 14px;
	font-family: inherit;
	color: #5e6c85;
}

.modal-dialog {
	max-width: 90vw;
	max-height: 100%;
}

.ReactModal__Overlay--after-open {
	z-index: 1000;
	background: rgba(0, 0, 0, 0.6) !important;
}

.ReactModal__Content--after-open {
	border: none !important;
	background: transparent !important;
	display: flex;
	justify-content: center;
	align-items: center;
}
.react-international-phone-input-container {
	width: 100%;
}
.react-international-phone-input-container .react-international-phone-input {
	border: none !important;
}
.react-international-phone-country-selector-button {
	padding: 0.5rem 1rem !important;
	border: none !important;
	background: transparent !important;
}

.modal {
	margin-top: 50px;
	height: 800px;
}

.app-modal-content {
	border-radius: 10px;
	background: #fff;
	border: none;
	margin: 0 auto;
}

.modal-header {
	border-bottom: none;
	font-style: normal;
	font-weight: 500;
	font-size: 2.4rem;
	line-height: 2.6rem;
	color: var(--primary-color);
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px 30px 5px;
}

.modal-header::before,
.modal-header::after {
	display: none;
}

.modal-header h4.modal-title {
	font-size: 1.6rem;
	color: var(--primary-color);
	/* margin-bottom: 20px; */
}

.modal-header .close {
	padding: 10px;
	background-color: transparent;
	border: none;
	font-size: 2.4rem;
}

.rec-modal-body {
	padding: 0px 30px 30px;
}

.modal-xlg {
	max-width: 1200px !important;
}

.modal-lg {
	max-width: 900px !important;
}

.modal-sm {
	max-width: 500px !important;
}
.modal-normal {
	max-width: 700px !important;
}

.modal-xsm {
	max-width: 400px !important;
}

.modal-xsm .modal-title {
	font-size: 0.5rem;
}

.modal-dialog {
	width: 97vw;
	max-height: 80vh;
	/* overflow: scroll; */
}

.ReactModal__Overlay--after-open {
	z-index: 1000;
	background: rgba(0, 0, 0, 0.6) !important;
}

.ReactModal__Content--after-open {
	border: none !important;
	background: transparent !important;
	display: flex;
	justify-content: center;
	align-items: center;
	inset: 0 !important;
}
